import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"

const Refer = React.lazy(() => import("../components/Refer"))
const component = Resource[process.env.GATSBY_SITE_VERSION]

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.refer.SEO.title}
            keywords={component.refer.SEO.keywords}
            description={component.refer.SEO.description}
          />
          <Layout>
            <Refer />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
